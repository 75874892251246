import Vue from 'vue';
import moment from 'moment-timezone';
import { bus } from '@/helpers/bus';
import CONFIG_QUERY from '@/queries/config';
import UPDATE_MUTATION from '@/queries/mutations/update';
import CREATE_MUTATION from '@/queries/mutations/create';

const defaultSettings = {
  entityDuplication: { type: 'boolean', value: false },
  resizerUrl: { type: 'text', value: '', required: true },
  uploaderUrl: { type: 'text', value: '', required: true },
  mediaUrl: { type: 'text', value: '', required: true },
  systemFieldsPrefix: { type: 'text', value: 'system_' },
  gmapsApiKey: { type: 'text', value: '' },
  datesLocalTimezone: { type: 'boolean', value: false, required: true },
  datesTimezone: { type: 'timezone', value: 'UTC', required: true },
  userInfoUrl: { type: 'text', value: '/private/me', required: true },
  renderDisabledFields: {
    type: 'select',
    value: 'disabled',
    required: true,
    options: ['disabled', 'readOnly', 'hidden'],
  },
};

const erorrLinkGen = (text, cb) => (h) => h('a', { on: { click: cb } }, text);

class AppSettings {
  constructor() {
    this.loading = true;
    this.locale = null;
    this.params = null;
    this.apolloClient = null;
    this.configId = null;
  }

  get(paramName) {
    return this.params[paramName].value;
  }

  async load(store, transProvider) {
    return new Promise((resolve, reject) => {
      this.loading = true;
      this.apolloClient = store.state.apolloClientOldApi;
      this.apolloClient
        .query({
          ...CONFIG_QUERY,
          variables: {
            name: 'glob_AppParams',
          },
        })
        .then(({ data }) => {
          const config = data.table.documents[0]?.data.config || '{}';
          this.config = typeof config === 'string' ? JSON.parse(config) : config;
          this.loading = false;
          this.configId = data.table.documents[0]?.id;
          this.locale = this.config.locale || {};
          this.params = Object.keys(defaultSettings).reduce((acc, key) => {
            acc[key] = { ...defaultSettings[key] };
            if (this.config[key] !== undefined) acc[key].value = this.config[key];
            return acc;
          }, {});

          if (!this.params.datesLocalTimezone.value) {
            moment.tz.setDefault(this.params.datesTimezone.value);
          }

          this.createMeta();
          resolve();
        })
        .catch((error) => {
          this.loading = false;
          let btn;

          if (store.state.user.isConstructor) {
            btn = erorrLinkGen(transProvider.$t('appSettings.openSettings'), () => {
              bus.$emit('appSettingsLoadError');
            });
          } else {
            btn = erorrLinkGen(transProvider.$t('appSettings.resetAuthToken'), () => {
              bus.$emit('logout');
            });
          }

          bus.$emit('error', {
            message: transProvider.$t('appSettings.error.loadingError'),
            description: error.message,
            btn,
          });

          reject(error);
        });
    });
  }

  save(params, transProvider) {
    const config = Object.entries(params).reduce((acc, [key, param]) => {
      acc[key] = param.value;
      return acc;
    }, {});

    const request = this.apolloClient.mutate({
      mutation: this.configId ? UPDATE_MUTATION : CREATE_MUTATION,
      variables: {
        type: 'Config',
        id: this.configId,
        data: {
          title: 'glob_AppParams',
          config: JSON.stringify({
            ...config,
            locale: this.locale,
          }),
        },
      },
    });

    request.then(({ data }) => {
      if (data.create) {
        this.configId = data.create.document.id;
      }
    });

    request.catch(() => {
      bus.$emit('error', {
        message: transProvider.$t('appSettings.saveError'),
      });
    });

    return request;
  }

  createMeta() {
    document.title = this.config.meta?.title || '';

    const head = document.querySelector('head');
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = this.config.meta?.favicon || head.dataset.defaultFavicon;
    head.appendChild(favicon);
  }
}

export const appSettings = Vue.observable(new AppSettings());
