import polyfills from './polyfills'; // eslint-disable-line
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import Antd from 'ant-design-vue';
import VScrollLock from 'v-scroll-lock';
import VClickOutside from 'v-click-outside';
import moment from 'moment';
import momentRu from 'moment/locale/ru';
import store from '@/store';
import router from '@/router';
import { bus, Cookies, XHR, parseJson, storage } from '@/helpers';
import CONFIG_QUERY from '@/queries/config';
import App from '@/App.vue';
import { apolloProvider } from './apolloProvider';
import LocaleService, { i18n } from './services/LocaleService';
import AuthService from './services/AuthService';

console.log('main start');
// Cookies.set('J_SESSION', 1, 345);

Vue.config.productionTip = false;
moment.locale('ru', [momentRu]);

XHR.initialize(store, router);
polyfills();

async function fetchAuthConfig() {
  console.log('main fetchAuthConfig');

  let authConfig = {
    providers: [
      {
        title: '',
        endpoint: storage.get('VUE_APP_AUTH_URL') || process.env.VUE_APP_AUTH_URL,
      },
    ],
  };

  try {
    const { data } = await store.mutate.sendCookieslessApolloQuery(
      {
        ...CONFIG_QUERY,
        variables: { name: 'glob_Auth' },
      },
      apolloProvider.defaultClient,
    );

    if (data.table.totalCount) {
      authConfig = parseJson(data.table.documents[0].data.config);
    }

    console.log('main authConfig loaded', authConfig);
    store.mutate.setAuthConfig(authConfig);
  } catch (error) {
    console.error('Auth config request error');
    throw error;
  }
}

async function initKeycloak({ redirectToRoot }) {
  console.log('main init kk');
  await AuthService.initKeycloak(store);
  console.log('main init kk done. will redirect to root: ', redirectToRoot);
  if (redirectToRoot) window.location.href = '/';
}

async function init() {
  if (!Cookies.get('J_SESSION') && localStorage.getItem('J_SESSION')) {
    Cookies.set('J_SESSION', 1, localStorage.getItem('J_SESSION'));
  }
  if (!Cookies.get('J_REFRESH') && localStorage.getItem('J_REFRESH')) {
    Cookies.set('J_REFRESH', 1, localStorage.getItem('J_REFRESH'));
  }

  console.log(
    'main init',
    window.location.pathname,
    !!localStorage.getItem('J_SESSION'),
    !!localStorage.getItem('J_REFRESH'),
  );

  await fetchAuthConfig();

  const isAuthCallbackPage = window.location.pathname === '/auth-callback';
  const isAuthorized = localStorage.getItem('J_SESSION') && localStorage.getItem('J_REFRESH');

  if (!isAuthorized || localStorage.getItem('KK_AUTH') === '1') {
    await initKeycloak({ redirectToRoot: isAuthCallbackPage });
  }

  console.log('main init 2');
  Vue.use(VueApollo);
  Vue.use(Antd);
  Vue.use(VScrollLock);
  Vue.use(VClickOutside);

  Vue.mixin({
    methods: {
      reemit(eventName, args = []) {
        this.$emit(eventName, ...args);
      },
      emitError(message, description) {
        bus.$emit('error', {
          message,
          description,
        });
      },
    },
  });

  store.mutate.setApolloClients(apolloProvider.defaultClient, apolloProvider.clients.newApiClient);

  console.log('main init 3');
  LocaleService.init().then(() => {
    console.log('main init 4');
    new Vue({
      i18n,
      router,
      apolloProvider,
      render: (h) => h(App),
    }).$mount('#app');
  });
}

init();
