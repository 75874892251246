<template>
  <a-layout-header
    ref="wrap"
    class="nav-header"
  >
    <div
      class="nav-wrap"
      :style="computedMenuStyle"
    >
      <a-menu
        v-if="computedMenuStyle"
        class="nav"
        mode="horizontal"
        :selectedKeys="selectedKeys"
        :inlineIndent="inlineIndent"
        @click="reemit('click', arguments)"
      >
        <sidebar-section
          v-for="section of sidebar"
          :key="section.id"
          :menuItem="section"
        />
      </a-menu>
    </div>
    <the-header
      ref="header"
      @logout="$emit('logout')"
      @openAppSettings="$emit('openAppSettings')"
    />
  </a-layout-header>
</template>

<script>
import store from '@/store';
import TheHeader from '@/components/header/TheHeader.vue';
import SidebarSection from './SidebarSection.vue';

export default {
  name: 'SidebarHorizontalRoot',
  components: {
    SidebarSection,
    TheHeader,
  },

  props: {
    sidebar: {
      type: Array,
      default: () => [],
    },
    selectedKeys: {
      type: Array,
      required: true,
    },
    inlineIndent: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      isMounted: false,
    };
  },

  computed: {
    computedMenuStyle() {
      if (!this.isMounted) return null;

      const width =
        store.state.windowWidth -
        parseInt(getComputedStyle(this.$refs.wrap.$el).paddingLeft) -
        parseInt(getComputedStyle(this.$refs.wrap.$el).paddingRight) -
        this.$refs.header.$el.offsetWidth;

      return {
        width: `${width}px`,
      };
    },
  },

  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss">
.menu--horizontal {
  .ant-menu-submenu.ant-menu-submenu-horizontal {
    padding-right: 20px;
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: -2px;
      width: 20px;
      background-color: #fff;
      content: '';
    }
  }

  .ant-menu-submenu-title {
    padding-left: 0;
    padding-right: 0;
  }

  .nav {
    &.ant-menu {
      border-right: none;
      margin: 4px 10px 10px 0;
      padding: 0;
      border-bottom: none;

      .ant-menu-item,
      .ant-menu-submenu-title {
        width: initial;
      }
    }

    &__top-section {
      text-transform: uppercase;
    }

    &-trigger {
      margin-left: 27px;
      margin-top: 20px;
      font-size: 15px;
    }
  }

  .ant-menu-submenu .ant-menu-submenu > .ant-menu-submenu-title {
    padding-right: 35px;
    .ant-menu-submenu-arrow {
      right: 12px;
    }
  }
  .ant-menu-item-group-title,
  .ant-menu-overflowed-submenu > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    display: none;
  }
  .ant-menu-item-group {
    padding: 0;
  }
  .ant-menu-item-group-list .ant-menu-item {
    padding: 0 20px;
  }

  .nav-wrap {
    position: relative;
  }

  .nav-mode-switch {
    position: absolute;
    top: 1px;
    left: 25px;
    font-size: 18px;
  }

  .nav-header {
    display: flex;
    padding: 0 24px;
    height: 61px;
  }

  .ant-menu,
  .ant-layout-sider,
  .ant-layout-header {
    transition: none !important;
  }

  .ant-menu-inline .ant-menu-selected::after,
  .ant-menu-inline .ant-menu-item-selected::after {
    display: none;
  }

  .ant-menu-horizontal .ant-menu-selected::after,
  .ant-menu-horizontal .ant-menu-item-selected::after {
    display: none;
  }

  @media (min-width: $desktopBreakpoint) {
    .nav {
      &.ant-menu {
        //padding: 20px 0;
      }

      .ant-menu-item-group-title {
        padding-left: 24px;
      }
    }
  }
}
</style>
